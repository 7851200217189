import {RecoilRoot} from 'recoil';
import {RouterProvider} from 'react-router-dom';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {QueryClient, QueryClientProvider, QueryCache} from '@tanstack/react-query';
import {ScreenshotProvider, ThemeProvider, useApiErrorBoundary} from './hooks';
import {router} from './routes';

const App = () => {
    const {setError} = useApiErrorBoundary();

    const queryClient = new QueryClient({
        queryCache: new QueryCache({
            onError: (error) => {
                if (error?.response?.status === 401) {
                    setError(error);
                }
            },
        }),
    });

    return (
        <QueryClientProvider client={queryClient}>
            <RecoilRoot>
                <ThemeProvider>
                    <RouterProvider router={router}/>
                    <ReactQueryDevtools initialIsOpen={false} position="top-right"/>
                </ThemeProvider>
            </RecoilRoot>
        </QueryClientProvider>
    );
};

export default () => (
    <ScreenshotProvider>
        <App/>
    </ScreenshotProvider>
);
